import React from "react";
import Layout from "../../../components/Layout";
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";

import { Link, graphql ,navigate} from "gatsby";
import Video from "../../../components/Video";
// import Markdown from "react-markdown";
// import rehypeRaw from 'rehype-raw'
import BackgroundImage from "gatsby-background-image";
import Accordion from "../../../components/Accordion";

// const markdown = `A paragraph with *emphasis* and **strong importance**.`

const ComponentName = ({ data }) => {
  const daa = data.strapiServices;
  const servicesList = data.allStrapiServices.nodes;
  const ProductsList=data.allStrapiProducts.nodes;
  console.log(daa.youtubeLink);
  return (
    <Layout title={data.strapiServices.title}>
      <div className="services">
        <BackgroundImage
        backgroundColor={`#0080FF`}
        fluid={daa.image.localFile.childImageSharp.fluid}
      //  style={{backgroundRepeat: "no-repeat",
        //        backgroundSize: "cover",
          //      }}
         className="hero">
         <div className="overlay">
           <h1>{daa.titleHindi}</h1>
          </div>
        </BackgroundImage>

        {/* <div className="hero" 
        style={{
                background:
                  "  linear-gradient(to right, rgb(19, 19, 19,0.3) 0, rgba(19, 19, 19, 0.3) 100%),url(" +
                      daa.image.localFile.childImageSharp.fluid.srcWebp
                    +
                  ")",
                backgroundRepeat: "repeat, no-repeat",
                backgroundSize: "auto, cover",
              }}
        >
          <h1>{daa.title}</h1>
        </div> */}

        <div className="ssbody wrapper_max">
        <div className="naviBody">
        <div className="navigation">
            <h3>हमारी सेवाएँ</h3>           
            <div className="list">
              {servicesList.map((v, k) => (
                <Link activeClassName="active" to={"/services/hindi/"+v.slug}>
                  <div key={k}>
                    <p>{v.titleHindi}</p>
                    <FaAngleRight />
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <div className="navigation">
            <h3>हमारे उत्पाद</h3>
            <div className="list list2">
              {ProductsList.map((v, k) => (
                <Link activeClassName="active" to={"/products/hindi/"+v.slug}>
                  <div key={k}>
                    <p>{v.hindiTitle}</p>
                    <FaAngleRight />
                  </div>
                </Link>
              ))}
            </div>
          </div>

        
          </div>

          <div className="content">
          {/* <div className="hindiLink"  style={{cursor:'pointer'}} onClick={()=>{  navigate('/services/'+daa.slug)}} data-nosnippet>Read in English</div> */}
            <h2>{daa.titleHindi}</h2>
            {/* <p>
                {daa.body}
            </p> */}
            {/* <Markdown className='page-body' rehypePlugins={[rehypeRaw]} children={daa.body} /> */}
            <div
              className="page-body"
              dangerouslySetInnerHTML={{ __html: daa.bodyHindi }}
            />
              <Accordion data={daa.hindiSection}/>
          </div>
        </div>
      </div>

      {daa.youtubeLink != null &&
        daa.youtubeLink != "null" &&
        daa.youtubeLink != "" && <Video url={daa.youtubeLink} />}
    </Layout>
  );
};

export const query = graphql`
  query servicesQueryAndServicesQuery($slug: String!) {
    strapiServices(slug: { eq: $slug }) {
      id
      slug
      title
      body
      youtubeLink
      bodyHindi
      titleHindi
       hindiSection{
        body
        title
      }
      image {
        localFile {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allStrapiProducts {
      nodes {
        slug
        hindiTitle
      }
    }
    allStrapiServices {
      nodes {
        slug
        titleHindi
      }
    }
  }
`;

export default ComponentName;
